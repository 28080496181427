body{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color';
}

.rbc-show-more{
    text-decoration: none;
    color: #1976d2;
}

#DialogContentAgendar .MuiTextField-root{
    width: 100%;
}

.rbc-agenda-empty{
    text-align: center !important;
    position: relative;
    top: 25%;
}

.css-1kty9di-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
    color: rgba(0, 0, 0, 0.38) !important;
}

.rbc-event, .rbc-event.rbc-selected {
    background-color: #0A192D;
}

.rbc-event:focus, .rbc-day-slot .rbc-background-event:focus {
    outline: 5px auto rgba(89, 90, 90, 0.3);
}