*{
    font-family: 'Open Sans', sans-serif !important;
}

html, body{
    background-color: #e7e8ea;    
}

::-webkit-scrollbar{
    width: 7px !important;   
    height: 7px !important;    
}

::-webkit-scrollbar-thumb{
    background-color: #c1c1c1 !important;
    border-radius: 20px !important;    
}

#CardHeaderEV .css-sgoict-MuiCardHeader-action {
    -webkit-flex: 1 0 auto !important;
    -ms-flex: 1 0 auto !important;
    flex: 1 0 auto !important;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: flex-start;
    align-self: flex-start;
    margin-top: -4px;
    margin-right: -8px;
    margin-bottom: -4px;
}

.css-n3ipqf-MuiButtonBase-root-MuiChip-root{
    color: rgb(255 255 255) !important;
    background-color: rgb(59 71 87) !important;
}

.css-n3ipqf-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon {    
    color: rgba(255, 255, 255, 0.7) !important;    
}